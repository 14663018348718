import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { addAirportDestinations } from "shared/data/actions/destinations";
import golApi from "shared/gol-api";

import {
  startUniversalLoading,
  stopUniversalLoading,
} from "../data/actions/loader";
import { countPassengers } from "../lib/functions";
import {
  PassengerTypes,
  PassengersCount,
} from "../lib/golObjectTypes/PassengerTypes";

export const ERROR_NOT_AVAILABLE_COMBINATIONS =
  "ERROR_NOT_AVAILAlBE_COMBINATIONS";
export const ERROR_NO_DATES_AVAILABLE = "ERROR_NO_DATES_AVAILABLE";
export const BEST_OFFER_MAX_PASSENGERS_COUNT = 9;

export function useListBestOffers(
  specialOfferEnabled: boolean,
  alternativeCurrency?: string
): {
  specialOffers: any;
  airportsCodeBook: {};
} {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    specialOffers: [],
    airportsCodeBook: {},
  });

  useEffect(() => {
    const loadBestOffersList = async () => {
      if (specialOfferEnabled) {
        const response = await golApi.listSpecialoffers(
          alternativeCurrency ? { alternativeCurrency } : {}
        );
        const { codebook, data } = response;
        const specialOffers = data?.ListSpecialoffers?.SpecialofferItem || [];

        dispatch(addAirportDestinations(codebook?.Airports?.Airport || []));
        const airportsCodeBook = {};

        Object.values(codebook?.Airports?.Airport || []).forEach(
          (airport: any) => {
            airportsCodeBook[airport.Code] = airport.$t;
          }
        );

        setState({ airportsCodeBook, specialOffers });
      }
    };

    loadBestOffersList();
    // eslint-disable-next-line
  }, [specialOfferEnabled]);

  return state;
}

interface dateObj {
  dateFrom: string;
  dateTo: string;
}

interface IBestOffers {
  departureDates: dateObj | null;
  returnDates: dateObj | null;
  selectedDepartureDate: Object;
  setSelectedDepartureDate: (date: Object) => void;
  selectedReturnDate: Object;
  setSelectedReturnDate: (date: Object) => void;
  reference: string;
  error: boolean | string;
  setError: (value: string | boolean) => void;
  passengers: PassengersCount;
  addPassenger: (types: PassengerTypes) => void;
  removePassenger: (type: PassengerTypes) => void;
  getDepartureDates: () => [];
  getReturnDates: () => [];
  onCalendarChange: (date: Object) => void;
  onCalendarReturnChange: (date: Object) => void;
  checkSpecialOffersAvailability: (
    startDate: string,
    endDate: string
  ) => Promise<void>;
}

export function useBestOffers({
  specialOfferId,
  query: { dateFrom0, dateTo0, dateFrom1, dateTo1 },
  adjustUrlCallback,
}: {
  specialOfferId: string;
  query: {
    dateFrom0: string;
    dateTo0: string;
    dateFrom1: string;
    dateTo1: string;
  };
  adjustUrlCallback: (obj) => void;
}): IBestOffers {
  const dispatch = useDispatch();

  const [queryDates, setQueryDates] = useState(null);

  const [passengers, setPassengers] = useState({
    ADT: 1, // adult
    INF: 0, // infant
    CHD: 0, // child/dítě - must be CHD when sending request
    YTH: 0, // youth/mládežník
    YCD: 0, // senior})
  });

  const [initLoad, setInitLoad] = useState(true);
  const [isReturnFlight, setIsReturnFlight] = useState(null);

  const [selectedDepartureDate, setSelectedDepartureDate] = useState(null);
  const [selectedReturnDate, setSelectedReturnDate] = useState(null);

  const [departureDates, setDepartureDates] = useState(null);
  const [returnDates, setReturnDates] = useState(null);

  const [calendarDateCombinations, setCalendarDateCombinations] = useState([]);
  const [reference, setReference] = useState();
  const [error, setError] = useState(null);

  const formattedPassengers = formatPassengers(passengers);

  const dates = [departureDates, returnDates];

  const stringifyDates = JSON.stringify(dates);
  const stringifyPassengers = JSON.stringify(formattedPassengers);

  const removePastDatesFromQuery = useCallback(() => {
    const correctedDatesObj = { ...queryDates };

    correctedDatesObj.dateFrom0 = getDateNotInPast(dateFrom0);
    correctedDatesObj.dateTo0 = dateTo0;

    if (dateFrom1 !== undefined) {
      correctedDatesObj.dateFrom1 = getDateNotInPast(dateFrom1);
      correctedDatesObj.dateTo1 = dateTo1;
    }

    setQueryDates({ ...correctedDatesObj });
    adjustUrlCallback(correctedDatesObj);
    return correctedDatesObj;
  }, [
    dateFrom0,
    dateFrom1,
    dateTo0,
    dateTo1,
    queryDates,
    setQueryDates,
    adjustUrlCallback,
  ]);

  const getDatesFromQuery = useCallback(
    (initQueryDates) => {
      const datesObj = [];
      if (
        initQueryDates?.dateFrom0 !== undefined &&
        initQueryDates?.dateTo0 !== undefined
      ) {
        const returnDate = getMonthWithLastDay(
          moment(initQueryDates?.dateFrom0).add(2, "months")
        );
        const newDepartureDates = {
          dateFrom: getDateNotInPast(initQueryDates?.dateFrom0),
          dateTo: moment(returnDate).isBefore(initQueryDates?.dateTo0)
            ? moment(returnDate).format("YYYY-MM-DD")
            : initQueryDates?.dateTo0,
        };
        datesObj.push(newDepartureDates);

        setDepartureDates({ ...newDepartureDates });
      }

      if (
        initQueryDates?.dateFrom1 !== undefined &&
        initQueryDates?.dateTo1 !== undefined
      ) {
        const returnDate = getMonthWithLastDay(
          moment(initQueryDates?.dateFrom1).add(2, "months")
        );
        const newReturnDates = {
          dateFrom: getDateNotInPast(initQueryDates?.dateFrom1),
          dateTo: moment(returnDate).isBefore(initQueryDates?.dateTo1)
            ? moment(returnDate).format("YYYY-MM-DD")
            : initQueryDates?.dateTo1,
        };
        datesObj.push(newReturnDates);

        setReturnDates({ ...newReturnDates });
      }
      return datesObj;
    },
    [setDepartureDates, setReturnDates]
  );

  const initCheckSpecialOffers = async (
    initQueryDates,
    dateObj,
    iteration = null
  ) => {
    try {
      const response = await golApi.checkSpecialoffers({
        specialOfferId,
        dates: dateObj,
        passengers: formattedPassengers,
      });

      if (response.success === false) {
        // eslint-disable-next-line no-console
        console.error(response.errorMsg);
        return;
      }

      const dateCombination =
        response?.data?.AvailableCalendar?.CalendarDateCombinations
          ?.DateCombination;

      const departureDateFrom = moment(initQueryDates.dateFrom0)
        .add(3, "months")
        .format("YYYY-MM");
      const departureDateTo = getMonthWithLastDay(
        moment(initQueryDates.dateFrom0).add(2, "months")
      );

      const returnDateFrom = moment(initQueryDates.dateFrom1)
        .add(3, "months")
        .format("YYYY-MM");
      const returnDateTo = getMonthWithLastDay(
        moment(initQueryDates.dateFrom1).add(2, "months")
      );

      const departureFirstCalendar = {
        dateFrom: initQueryDates.dateFrom0,
        dateTo: moment(initQueryDates.dateTo0).isBefore(departureDateTo)
          ? initQueryDates.dateTo0
          : departureDateTo,
      };
      const departureSecondCalendar = {
        dateFrom: `${departureDateFrom}-01`,
        dateTo: initQueryDates.dateTo0,
      };

      const returnFirstCalendar = initQueryDates.dateFrom1
        ? {
            dateFrom: initQueryDates.dateFrom1,
            dateTo: returnDateTo,
          }
        : null;
      const returnSecondCalendar = initQueryDates.dateFrom1
        ? {
            dateFrom: `${returnDateFrom}-01`,
            dateTo: initQueryDates.dateTo1,
          }
        : null;
      if (!dateCombination) {
        if (!isReturnFlight) {
          await initCheckSpecialOffers(initQueryDates, [
            departureSecondCalendar,
          ]);
        } else if (iteration === 0) {
          if (
            moment(returnFirstCalendar.dateFrom).isBefore(
              departureSecondCalendar.dateFrom
            )
          ) {
            await initCheckSpecialOffers(
              initQueryDates,
              [departureSecondCalendar, departureSecondCalendar],
              1
            );
          } else {
            await initCheckSpecialOffers(
              initQueryDates,
              [departureSecondCalendar, returnFirstCalendar],
              1
            );
          }
        } else if (iteration === 1) {
          await initCheckSpecialOffers(
            initQueryDates,
            [departureFirstCalendar, returnSecondCalendar],
            2
          );
        } else if (
          iteration === 2 &&
          initQueryDates.dateFrom0 === dateObj[0].dateFrom &&
          initQueryDates.dateTo1 === dateObj[1]?.dateTo
        ) {
          await initCheckSpecialOffers(initQueryDates, [
            departureSecondCalendar,
            returnSecondCalendar,
          ]);
        }
      } else {
        const firstDepartureDateAvailable = dateCombination[0].Departure;
        const firstArrivalDateAvailable = dateCombination[0].Arrival;

        if (dateObj[0]?.dateFrom !== firstDepartureDateAvailable) {
          const departureDateObj = moment(firstDepartureDateAvailable).add(
            2,
            "months"
          );
          const dd = moment(getMonthWithLastDay(departureDateObj)).format(
            "YYYY-MM-DD"
          );
          const departureDateTo1 = moment(initQueryDates.dateTo0).isBefore(dd)
            ? initQueryDates.dateTo0
            : dd;

          if (!firstArrivalDateAvailable) {
            await initCheckSpecialOffers(initQueryDates, [
              {
                dateFrom: firstDepartureDateAvailable,
                dateTo: departureDateTo1,
              },
            ]);
            return;
          }

          const returnDateToObj = moment(firstArrivalDateAvailable).add(
            2,
            "months"
          );

          const rd = moment(getMonthWithLastDay(returnDateToObj)).format(
            "YYYY-MM-DD"
          );

          const returnDateTo1 = moment(initQueryDates.dateTo1).isBefore(rd)
            ? initQueryDates.dateTo1
            : rd;

          await initCheckSpecialOffers(initQueryDates, [
            { dateFrom: firstDepartureDateAvailable, dateTo: departureDateTo1 },
            { dateFrom: firstArrivalDateAvailable, dateTo: returnDateTo1 },
          ]);
          return;
        }
        setCalendarDateCombinations(dateCombination);
        setDepartureDates({ ...dateObj[0] });
        if (dateObj[1]) setReturnDates({ ...dateObj[1] });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setError(ERROR_NO_DATES_AVAILABLE);
    } finally {
      dispatch(stopUniversalLoading());
      setInitLoad(false);
    }
  };

  const getMonthWithLastDay = (date) => {
    const d = new Date(date);
    const lastDayOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    return lastDayOfMonth.toLocaleDateString("en-CA");
  };

  useEffect(() => {
    const initQueryDates = removePastDatesFromQuery();

    const dateObj = getDatesFromQuery(initQueryDates);

    if (initQueryDates.dateFrom1 && initQueryDates.dateTo1) {
      setIsReturnFlight(true);
    } else {
      setIsReturnFlight(false);
    }
    if (isReturnFlight === null) return;
    dispatch(startUniversalLoading());
    initCheckSpecialOffers(initQueryDates, dateObj, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReturnFlight]);

  const checkSpecialOffers = useCallback(
    async (startDate = null, endDate = null) => {
      setError(false);
      if (departureDates === null) return;
      dispatch(startUniversalLoading());
      try {
        const response = await golApi.checkSpecialoffers({
          specialOfferId,
          dates,
          passengers: formattedPassengers,
          ...(startDate && { startDate }),
          ...(endDate && { endDate }),
        });

        const dateCombination =
          response?.data?.AvailableCalendar?.CalendarDateCombinations
            ?.DateCombination;

        if (!dateCombination) {
          setError(ERROR_NO_DATES_AVAILABLE);
        } else {
          setCalendarDateCombinations(dateCombination);
          setReference(response.data?.CheckedSpecialOffer?.Reference?.$t);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setError(ERROR_NO_DATES_AVAILABLE);
      } finally {
        dispatch(stopUniversalLoading());
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifyDates, stringifyPassengers, specialOfferId]
  );

  useEffect(() => {
    if (initLoad) return;

    checkSpecialOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifyDates, stringifyPassengers, checkSpecialOffers]);

  const checkSpecialOffersAvailability = async (startDate, endDate) => {
    await checkSpecialOffers(startDate, endDate);
  };

  const addPassenger = (type: PassengerTypes) => {
    if (countPassengers(passengers) >= BEST_OFFER_MAX_PASSENGERS_COUNT) {
      return;
    }
    setPassengers({
      ...passengers,
      [type]: passengers[type] + 1,
    });
  };

  const removePassenger = (type: PassengerTypes) => {
    if (passengers[type] < 1) {
      return;
    }
    setPassengers({
      ...passengers,
      [type]: passengers[type] - 1,
    });
  };

  const getReturnDates = () => getCalendarDates("Arrival");
  const getDepartureDates = () => getCalendarDates("Departure");
  const getCalendarDates = (type: "Departure" | "Arrival"): [] => {
    return calendarDateCombinations?.reduce((acc, currentValue) => {
      if (type === "Arrival") {
        if (currentValue.Arrival === "") return acc;
        if (selectedDepartureDate) {
          if (
            selectedDepartureDate.toDateString() !==
            new Date(currentValue.Departure).toDateString()
          ) {
            return acc;
          }
        }
      }

      const exists = acc.find(
        (wanted) =>
          new Date(wanted).toDateString() ===
          new Date(currentValue[type]).toDateString()
      );
      if (!exists) acc.push(new Date(currentValue[type]));
      return acc;
    }, []);
  };

  const onCalendarChange = (date) => {
    const fromDate = moment(date).isBefore(queryDates.dateFrom0)
      ? queryDates.dateFrom0
      : moment(date).format("YYYY-MM-DD");
    const formattedDateFrom = getDateNotInPast(fromDate);

    const tillDate = moment(fromDate).add(2, "months");
    const td = getMonthWithLastDay(tillDate);
    const formattedDateTo = moment(td).isBefore(queryDates.dateTo0)
      ? td
      : queryDates.dateTo0;

    setDepartureDates({
      dateFrom: formattedDateFrom,
      dateTo: formattedDateTo,
    });

    const isBefore =
      returnDates?.dateFrom &&
      moment(returnDates.dateFrom).isBefore(formattedDateFrom);

    if (isBefore) {
      onCalendarReturnChange(formattedDateFrom);
    }
  };

  const onCalendarReturnChange = (date) => {
    const fromDate = moment(date).isBefore(queryDates.dateFrom1)
      ? queryDates.dateFrom1
      : moment(date).format("YYYY-MM-DD");
    const tillDate = moment(fromDate).add(2, "months");
    const td = getMonthWithLastDay(tillDate);

    const formattedDateFrom = getDateNotInPast(fromDate);
    const formattedDateTo = moment(td).isBefore(queryDates.dateTo1)
      ? td
      : queryDates.dateTo1;

    setReturnDates({
      dateFrom: formattedDateFrom,
      dateTo: formattedDateTo,
    });

    const isBefore = moment(formattedDateFrom).isBefore(
      departureDates.dateFrom
    );
    if (isBefore) {
      setDepartureDates({
        dateFrom: formattedDateFrom,
        dateTo: moment(td).isBefore(departureDates.dateTo)
          ? td
          : queryDates.dateTo0,
      });
    }
  };

  return {
    departureDates,
    returnDates,
    selectedDepartureDate,
    setSelectedDepartureDate,
    selectedReturnDate,
    setSelectedReturnDate,
    reference,
    error,
    setError,
    passengers,
    addPassenger,
    removePassenger,
    getDepartureDates,
    getReturnDates,
    onCalendarChange,
    onCalendarReturnChange,
    checkSpecialOffersAvailability,
  };
}

const formatPassengers = (passengers): Array<PassengerTypes> => {
  return Object.keys(passengers).reduce((accumulator, current) => {
    const count = passengers[current];
    const passengerCodesArray = [];
    for (let i = 0; i < count; i++) {
      passengerCodesArray.push(current);
    }
    return [...accumulator, ...passengerCodesArray];
  }, []);
};

export const getDateNotInPast = (date: string): string =>
  moment().isBefore(date) ? date : moment().format("YYYY-MM-DD");
